/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-09-16 15:42:34 */

const Keys = {
  noBooking: 'noBooking',
  summary: 'summary',
  order: 'order',
  prev: 'prev',
  next: 'next',
  details: 'details',
  perPerson: 'perPerson',
  TravelDates: 'TravelDates',
  Extras: 'Extras',
  optionalExtras: 'optionalExtras',
  optional: 'optional',
  CustomerDetails: 'CustomerDetails',
  Confirmation: 'Confirmation',
  summaryTotalPrice: 'summaryTotalPrice',
  summaryAcceptDGR: 'summaryAcceptDGR',
  summaryDGRLink: 'summaryDGRLink',
  summaryAcceptDGRAfterLink: 'summaryAcceptDGRAfterLink',
  summaryAcceptTaC: 'summaryAcceptTaC',
  summaryPrivacyPolicy: 'summaryPrivacyPolicy',
  summaryAnd: 'summaryAnd',
  summaryTermsAndConditions: 'summaryTermsAndConditions',
  summaryAcceptDGRAndTaCError: 'summaryAcceptDGRAndTaCError',
  summaryAcceptDGRError: 'summaryAcceptDGRError',
  summaryAcceptTaCError: 'summaryAcceptTaCError',
  summaryMarketingConsent: 'summaryMarketingConsent',
  SummaryBookingError: 'SummaryBookingError',
  ConfirmationHeadline: 'ConfirmationHeadline',
  ConfirmationQuotationHeadline: 'ConfirmationQuotationHeadline',
  ConfirmationBookingNumber: 'ConfirmationBookingNumber',
  ConfirmationQuotationBookingNumber: 'ConfirmationQuotationBookingNumber',
  errorAdultCount: 'errorAdultCount',
  errorChildCount: 'errorChildCount',
  errorChildrenAge: 'errorChildrenAge',
  errorInfantCount: 'errorInfantCount',
  errorMinLength: 'errorMinLength',
  errorMaxLength: 'errorMaxLength',
  errorInvalidName: 'errorInvalidName',
  errorInvalidFirstMiddleName: 'errorInvalidFirstMiddleName',
  errorInvalidChar: 'errorInvalidChar',
  errorRequiredField: 'errorRequiredField',
  errorInvalidAdult: 'errorInvalidAdult',
  errorInvalidChild: 'errorInvalidChild',
  errorInvalidInfant: 'errorInvalidInfant',
  travelParticipantsHeader: 'travelParticipantsHeader',
  errorEmail: 'errorEmail',
  errorPhone: 'errorPhone',
  errorInvalidAgencyId: 'errorInvalidAgencyId',
  errorInvalidAgentName: 'errorInvalidAgentName',
  bookingAttemptErrorText: 'bookingAttemptErrorText',
  select: 'select',
  change: 'change',
  remove: 'remove',
  fillWithMock: 'fillWithMock',
  participantData: 'participantData',
  applicant: 'applicant',
  participant: 'participant',
  requiredFields: 'requiredFields',
  tac_header: 'tac_header',
  tac_firstSlot: 'tac_firstSlot',
  tac_secondSlot: 'tac_secondSlot',
  tac_thirdSlot: 'tac_thirdSlot',
  tac_mandatoryFieldError: 'tac_mandatoryFieldError',
  sendRequest: 'sendRequest',
  activityCreatedSuccessfully: 'activityCreatedSuccessfully',
  activityCreatedError: 'activityCreatedError',
  inboundLuggageSelectionHeader: 'inboundLuggageSelectionHeader',
  outboundLuggageSelectionHeader: 'outboundLuggageSelectionHeader',
  inboundLuggageSelectionSubHeader: 'inboundLuggageSelectionSubHeader',
  outboundLuggageSelectionSubHeader: 'outboundLuggageSelectionSubHeader',
  inboundLuggageSelectionInfo: 'inboundLuggageSelectionInfo',
  outboundLuggageSelectionInfo: 'outboundLuggageSelectionInfo',
  ADULT: 'ADULT',
  CHILD: 'CHILD',
  INFANT: 'INFANT',
  kilo: 'kilo',
  piece: 'piece',
  freeBaggageIncluded: 'freeBaggageIncluded',
  freeBaggageIncluded_plural: 'freeBaggageIncluded_plural',
  baggage_type_checked_plus_cabin: 'baggage_type_checked_plus_cabin',
  baggage_type_checked: 'baggage_type_checked',
  baggage_type_cabin: 'baggage_type_cabin',
  headlineText: 'headlineText',
  disclaimerText: 'disclaimerText',
  percentagePriceInsuranceLabel: 'percentagePriceInsuranceLabel'
}; export default Keys;